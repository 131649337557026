
import CustomPageHeader from '@/components/Layout/CustomPageHeader.vue';
import { useStore } from 'vuex';
import { computed, onBeforeMount, ref } from 'vue';
import { UserPresetCampaignType } from '@/types/preset-management/UserPresetCampaignType';
import { useRouter } from 'vue-router';

export default {
  name: 'ViewerNoDashboard',

  components: {
    CustomPageHeader,
  },

  setup() {
    const isReady = ref(false);
    const store = useStore();
    const accountId = computed(() =>
      store.getters['account/accountId'].toUpperCase()
    );
    const router = useRouter();

    onBeforeMount(async () => {
      await store.dispatch('presetFilter/loadShareCampaigns');
      const campaignShareWithMeList: UserPresetCampaignType[] =
        store.state.presetFilter.campaignShareWithMes;

      if (campaignShareWithMeList.length > 0) {
        router.push('/custom-campaign/' + campaignShareWithMeList[0].id);
      } else {
        router.push('/welcome');
      }
      setTimeout(() => {
        isReady.value = true;
      }, 100);
    });

    return {
      accountId,
      isReady,
    };
  },
};
